import React, { useState, useEffect } from 'react';
import {
    Navbar as Nav,
    Typography,
    Button,
    IconButton,
    Collapse,
} from "@material-tailwind/react";
import { Link } from 'react-router-dom';

const data = [
    {
        id: 0,
        title: "Bosh sahifa",
        link: "/"
    },
    {
        id: 1,
        title: "Jurnallar",
        link: "/journals"
    },
    {
        id: 2,
        title: "Konferensiyalar",
        link: "/conferences",
    },
    {
        id: 3,
        title: "Nashrlar",
        link: "/issues",
    },
    /* {
        id: 4,
        title: "Not Found",
        link: "/something"
    }, */
]

function Navbar() {
    const [openNav, setOpenNav] = useState(false);
    useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false));
    }, []);

    const navList = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            {
                data.map(item => {
                    return (
                        <Typography
                            as="li"
                            variant="small"
                            color="blue-gray"
                            className="p-1 font-normal"
                            key={item.id}
                        >
                            <Link to={item.link} className="flex items-center">
                                {item.title}
                            </Link>
                        </Typography>
                    )
                })
            }
        </ul>
    );
    return (
        <>
            <Nav className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
                <div className="flex items-center justify-between text-blue-gray-900">
                    <Link to="/">
                    <Typography className="mr-4 cursor-pointer py-1.5 font-bold text-orange-600">F-JOURNAL</Typography>
                    </Link>
                    <div className="flex items-center gap-4">
                        <div className="mr-4 hidden lg:block">{navList}</div>
                        <Link to="/get"> 
                        <Button
                            variant="gradient"
                            color="deep-orange"
                            size="sm"
                            className="hidden lg:inline-block"
                        >
                            <span>Bog'lanish</span>
                        </Button>
                        </Link>
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </div>
                <Collapse open={openNav}>
                    {navList}
                    <Link to="/get">
                    <Button variant="gradient" size="sm" color="deep-orange" fullWidth className="mb-2">
                        <span>Bog'lanish</span>
                    </Button>
                    </Link>
                </Collapse>
            </Nav>
        </>
    );
}

export default Navbar;