import { Typography } from "@material-tailwind/react";
import { FaTelegramPlane, FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
 
const LINKS = [
  {
    title: "Product",
    items: ["Overview"],
  },
  {
    title: "Company",
    items: ["About us"],
  },
  {
    title: "Resource",
    items: ["Blog"],
  },
];
 
const currentYear = new Date().getFullYear();
 
export default function Footer() {
  return (
    <footer className="relative w-full bg-gray-50 pt-5 pb-1">
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-2">
          <Typography variant="h5">
            F-Journal
          </Typography>
          {/* <div className="grid grid-cols-3 justify-between gap-4">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-3 font-medium opacity-40"
                >
                  {title}
                </Typography>
                {items.map((link) => (
                  <li key={link}>
                    <Typography
                      as="a"
                      href="#"
                      color="gray"
                      className="py-1.5 font-normal transition-colors hover:text-blue-gray-900"
                    >
                      {link}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div> */}
        </div>
        <div className="mt-1 flex w-full flex-col items-center justify-center border-t border-blue-gray-100 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="text-center font-normal text-blue-gray-900 md:mb-0"
          >
            &copy; {currentYear} <a href="https://material-tailwind.com/">F-Journal Platform</a>. All
            Rights Reserved.
          </Typography>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            <Typography as="a" target="_blank" href="https://t.me/f_journal_uz" className="opacity-80 transition-opacity hover:opacity-100">
              <FaTelegramPlane />
            </Typography>
            <Typography as="a" target="_blank" href="https://www.instagram.com/" className="opacity-80 transition-opacity hover:opacity-100">
              <FaInstagram />
            </Typography>
            <Typography as="a" target="_blank" href="https://www.facebook.com/" className="opacity-80 transition-opacity hover:opacity-100">
              <FaFacebook />
            </Typography>
            <Typography as="a" target="_blank" href="https://twitter.com/" className="opacity-80 transition-opacity hover:opacity-100">
              <FaTwitter />
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}