import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCgyIinXnMolnsa_P-zkyR0XtGbVxB_WZY",
    authDomain: "f-journal.firebaseapp.com",
    projectId: "f-journal",
    storageBucket: "f-journal.appspot.com",
    messagingSenderId: "1077559605376",
    appId: "1:1077559605376:web:43c4d36c96b698d28c4e65",
    measurementId: "G-FC9F70CP54"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);