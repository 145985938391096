import CryptoJS, { AES } from "crypto-js";
class Crypt {
    static encrypt(str, key = "252525") {
        return AES.encrypt(str.toString(), key).toString();
    }
    static decrypt(str, key = "252525") {
        return AES.decrypt(str, key).toString(CryptoJS.enc.Utf8);
    }
}

export default Crypt;