import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import gscholar from "../../assets/images/gscholar.png";
import zenodo from "../../assets/images/zenodo.jpg";
import openaccess from "../../assets/images/openaccess.png";
import doi from "../../assets/images/doi.png";
import openaire from "../../assets/images/openaire.jpg";

const indexingData = [
    {
        title: "Google Scholar",
        image: gscholar,
        link: "https://scholar.google.com/",
    },
    {
        title: "Zenodo",
        image: zenodo,
        link: "https://zenodo.org/",
    },
    {
        title: "Open Access",
        image: openaccess,
        link: "https://www.openaccess.nl/en",
    },
    {
        title: "DOI",
        image: doi,
        link: "https://doi.org/",
    },
    {
        title: "OpenAIRE",
        image: openaire,
        link: "https://www.openaire.eu/",
    }
];
 
export default function Indexing() {
  return (
    <div className="flex flex-row justify-around flex-wrap">
    {
        indexingData.map((data, index) => {
            return (
                <Card className="mt-5 w-40" key={index}>
                    <CardBody>
                        <img src={data.image} className="text-blue-500 w-auto max-h-12 mb-4" alt="Google Scholar" />
                        <Typography color="blue-gray" className="mb-2 text-sm">
                        {data.title}
                        </Typography>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <a href={data.link} className="inline-block">
                        <Button size="sm" variant="text" className="flex items-center text-[.6em] md:text-md">
                            Havolaga o'tish 
                            <ArrowLongRightIcon strokeWidth={2} className="w-4 h-4" />
                        </Button>
                        </a>
                    </CardFooter>
                </Card>
            );
        })
    }
        
    </div>
  );
}