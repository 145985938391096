// import logo from './logo.svg';
import './App.css';
// import { useEffect, useRef, useState } from 'react';
// import { db } from './firebase-config';
// import { collection, getDocs } from "firebase/firestore";
// import { getDownloadURL, list, ref } from 'firebase/storage';
// import { storage } from './firebase-config';
import { HelmetProvider } from 'react-helmet-async';
// import { Routes, Route } from "react-router-dom";
// import { Spinner, Button } from '@material-tailwind/react';
// import { cryptKey } from './app/config';

// import Crypt from './components/Crypt';

import AppRoutes from './app/AppRoutes';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


// const userCollectionRef = collection(db, "users");
// const fileRef = ref(storage, "ilm-fan/algoritm.pdf");

const provider = {};

function App() {
  // const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {

    // const getUsers = async () => {
    //   const data = await getDocs(userCollectionRef);
    //   // console.log(data.docs.map(doc => ({...doc.data(), id: doc.id})));
    //   setUsers(data.docs.map(doc => ({...doc.data(), id: doc.id})));
    //   // console.log('whoa')
    // };
    // getUsers();
    // getDownloadURL(fileRef).then(res => { console.log(res) });
    // list(fileRef).then(res => {
      // res.items.forEach(item => {
        // getDownloadURL(item).then(async url => {
          // console.log(url);
          // const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
          // iframeRef.current.src = url + "#toolbar=0";
        // });
      // });
    // });
  // }, []);


  return (
    <HelmetProvider context={provider}>
    {/* <BrowserView>
  <h1>This is rendered only in browser</h1>
</BrowserView>
<MobileView>
  <h1>This is rendered only on mobile</h1>
</MobileView> */}
      <AppRoutes />
    </HelmetProvider>
  );
}

export default App;
