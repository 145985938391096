import { Card, Typography, CardBody, CardHeader, Button, Carousel, IconButton } from "@material-tailwind/react";
import muqova from "../../assets/images/881.jpg";
import { db } from "../../firebase-config";
import { collection, getDocs, query, where, orderBy, endAt, limit, or, doc, getDoc, addDoc } from "firebase/firestore";
import React, {useEffect, useState, memo} from "react";
import { storage } from "../../firebase-config";
import { ref } from "firebase/storage";
import { Button as MuiButton } from "@mui/material";
import { pink } from '@mui/material/colors';
import { Link } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import libImage from "../../assets/images/background.png";
import mockup from "../../assets/images/mockup.png";
import { ArrowLongRightIcon, ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

// const Loader = () => {
//     return <LinearProgress color="primary" id="progress" style={{ display: 'none' }} className="!fixed !top-0 !right-0 !left-0 !z-[11]" />;
// }

// function load() {
//   document.getElementById("progress").style.display = "block";
// }


class Loader {
    static Container() {
        return <LinearProgress color="warning" id="progress" style={{ display: 'none' }} className="!fixed !top-0 !right-0 !left-0 !z-[11]" />;
    }
    static run() {
      document.getElementById("progress").style.display = "block";
    }
    static stop() {
      document.getElementById("progress").style.display = "none";
    }
}



const userCollectionRef = collection(db, "users");
const fileRef = ref(storage, "");
const userDoc = doc(db, "users", "1");
const Home = () => {
  // const [setLoading] = useOutletContext();
  const [loading, setLoading] = useState(false);
  // const [users, setUsers] = useState([]);
  // useEffect(() => {
  //   const getUsers = async () => {
  //     const q = query(userCollectionRef);
  //     // console.log((await getDoc(userDoc)).data());
  //     const data = await getDocs(q);
  //     setUsers(data.docs.map((doc) => ({...doc.data(), id: doc.id })));
  //   }
  //   getUsers();
  // }, []);
// console.log("home");
    return (
      <>
      <Helmet>
        <title>F-Journal</title>
        <meta name="description" content="F-Journal Platform of Scientific Journals" />
      </Helmet>
        <Carousel
        prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 -translate-y-2/4 left-4"
        >
          <ArrowLeftIcon strokeWidth={2} className="w-6 h-6" />
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 -translate-y-2/4 !right-4"
        >
          <ArrowRightIcon strokeWidth={2} className="w-6 h-6" />
        </IconButton>
      )}
        className="rounded-xl h-96 bg-center bg-cover" style={{ backgroundImage: `url(${libImage})` }}
        navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-[2] flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      <Card
      className="overflow-hidden h-full bg-transparent p-10 flex justify-center items-center flex-row">
      {/* <img
        src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
        alt="image 1"
        className="absolute top-0 left-0 h-full w-full object-cover"
      /> */}
      <Typography
      className="text-md sm:text-2xl md:text-2xl font-bold text-center text-white"
      color="white"
      >
        "Ilm-Fan" Journal of science
      </Typography>
      <img src={mockup} className="h-3/4 2xs:h-1/2 xs:h-1/2 rotate-[30deg] ml-5 md:ml-10" alt="Mock-up" />
      </Card>
      <Card
      className="overflow-hidden h-full bg-transparent p-10 flex justify-center items-center flex-row">
      {/* <img
        src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
        alt="image 1"
        className="absolute top-0 left-0 h-full w-full object-cover"
      /> */}
      <Typography
      className="text-md sm:text-2xl md:text-2xl font-bold text-center text-white"
      color="white"
      >
        Volume 1 <br />
        Issue 1
      </Typography>
      <img src={mockup} className="h-3/4 2xs:h-1/2 xs:h-1/2 rotate-[30deg] ml-5 md:ml-10" alt="Mock-up" />
      </Card>
    </Carousel>
    <div className="relative flex py-5 items-center my-5">
      <div className="flex-grow border-t border-gray-400"></div>
      <span className="flex-shrink mx-4 text-gray-700 text-2xl">Jurnallar</span>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
    <Card className="flex-row w-full max-w-[48rem]">
      <CardHeader shadow={false} floated={false} className="w-2/5 shrink-0 m-0 rounded-r-none">
        <img 
          src={muqova}
          alt="muqova"
          className="w-full h-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h6" color="blue" className="uppercase mb-4">ILM-FAN</Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          "ILM-FAN" Journal of science
        </Typography>
        <Typography color="gray" className="font-normal mb-8">
          Google Scholar, ZENODO, DOI, OpenAire, Open Access ilmiy-ommabop maqolalarni indexlovchi bazalarda indexlanuvchi AOKA(Axborot va Ommaviy Kommunikatsiya Agentligi) tomonidan litsenziyalangan jurnal
        </Typography>
        <Link to="/journals/ifjs" className="inline-block">
          <Button variant="text" className="flex items-center gap-2">
            Jurnalga o'tish 
            <ArrowLongRightIcon strokeWidth={2} className="w-4 h-4" />
          </Button>
        </Link>
      </CardBody>
    </Card>
    </>
    );
};
  
export default memo(Home);