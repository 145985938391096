import React from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Spinner,
} from "@material-tailwind/react";
import { db } from "../../firebase-config.js";
import { doc, getDoc } from "firebase/firestore";
import articleimage from "../../assets/images/articleimage.jpg";
import { BsFiletypePdf } from "react-icons/bs";
import Crypt from '../../app/Crypt.js';
import { Helmet } from 'react-helmet-async';
function Article() {
    const [issue, setIssue] = React.useState({
        journal: "ifjs",
        volume: "1",
        issue: "1",
    });
    const { id } = useParams();
    const [article, setArticle] = React.useState(null);
    React.useEffect(() => {
        const getArticle = async () => {
            // const docRef = doc(db, "articles", id);
            // const docSnap = await getDoc(docRef);
            // const issueRef = doc(db, "issues", docSnap.data().issue);
            // const issueSnap = await getDoc(issueRef);
            // if (docSnap.exists()) {              
            //   if(issueSnap.exists()) {
            //     setIssue({journal: issueSnap.data().journal, volume: issueSnap.data().Volume, issue: issueSnap.data().Issue});
            //   } else {
            //     console.log('whoa')
            //   }
            //   setArticle(docSnap.data());
              
            //   } else {
            //     // docSnap.data() will be undefined in this case
            //     console.log("No such document!");
            //   }
            const users = await fetch(`https://next-chi-two.vercel.app/api/article/${id}`).then((res) => res.json());
            setArticle(users);
        };
        getArticle();
    }, [id]);
    // console.log(article.issue)
    return (
      article ? (<Card className="mt-6 w-96">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.abstract} />
        <meta name="citation_title" content={article.title} />
        { article.author && article.author.map(author => (<meta key={author} name="citation_author" content={author} />))}
        <meta name="citation_publication_date" content="2023/05/20" />
        <meta name="citation_keywords" content={article.keywords} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.abstract} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="F-Journal" />
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <CardHeader color="blue-gray" className="relative h-56">
        <img src={articleimage} alt="img-blur-shadow" layout="fill" />
      </CardHeader>
      <CardBody>
        { article.title && (<Typography variant="h5" color="blue-gray" className="mb-2">
          {article.title}
        </Typography>)}
        <Typography className="font-bold">
          <span className='font-thin'>Author: </span>{article.author && article.author.map((author) => article.author.length > 1 ? author + ", " : author)}
        </Typography>
        {
           article.scadvisor && (<><hr className='my-2' />
        <Typography className="font-bold">
          <span className='font-thin'>Scientific Advisor: </span>{article.scadvisor}
        </Typography>
        </>)
        }
        <hr className='my-2' />
        <Typography className="font-bold">
          <span className='font-thin'>Abstract: </span>{article.abstract}
        </Typography>
        <hr className='my-2' />
        <Typography className="font-bold">
          <span className='font-thin'>Keywords: </span>{article.keywords}
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        {
            article.doi ? (<><Link to={article.doi}>
            <Button variant="gradient" className="flex items-center gap-3">
                View PDF ZENODO|DOI <BsFiletypePdf className="h-5 w-5" />
            </Button>
        </Link>
        <Link to={"/files/" + issue.journal + "_" + issue.volume + "." + issue.issue}>
            <Button variant="gradient" className="flex items-center gap-3">
                View Full PDF <BsFiletypePdf className="h-5 w-5" />
            </Button>
        </Link></>) : (<Link to={"files/" + Crypt.encrypt(JSON.stringify(issue), "252525")}>
            <Button variant="gradient" className="flex items-center gap-3">
                View Full PDF <BsFiletypePdf className="h-5 w-5" />
            </Button>
        </Link>)
        }
      </CardFooter>
    </Card>) : (<Spinner className="h-16 w-16 text-blue-500/10" />)
  )
}

export default Article