import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button
} from "@material-tailwind/react";
import { db } from "../../firebase-config.js";
import { doc, getDoc, query } from "firebase/firestore";
import articleimage from "../../assets/images/articleimage.jpg";
import { BsFiletypePdf } from "react-icons/bs";
import Crypt from '../../app/Crypt.js';
import { Spinner } from "@material-tailwind/react";

function Files() {
    const [pdf, setPdf] = useState(null);
    const { id } = useParams();
    const [uri, setUri] = useState(null);
  useEffect(() => {
    async function createPdf() {
      const fileName = id.substring(id.indexOf('_') + 1, id.length);
      const journal = id.substring(0, id.indexOf('_'));
      // const url = 'https://firebasestorage.googleapis.com/v0/b/f-journal.appspot.com/o/' + journal + '/' + fileName + '.pdf?alt=media&token=ef10f3cf-f592-44ac-9f3b-60b48dbfe6ab';
      const url = 'https://firebasestorage.googleapis.com/v0/b/f-journal.appspot.com/o/' + journal + '%2F' + fileName + '.pdf?alt=media&token=5e277257-84d6-4603-a567-33add7afb38c';
      // console.log(url);
      setUri(url);
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const newPdf = await PDFDocument.create();
      // for(let x = 0; x <= 9; x++) {
        const [firstDonorPage] = await newPdf.copyPages(pdfDoc, [])
        newPdf.addPage(firstDonorPage)
      // }
      /* const page = pdfDoc.addPage([350, 400]);
      page.moveTo(110, 200);
      page.drawText('Hello World!'); */
      const pdfBytes = await pdfDoc.save()
      const bytes  = new Uint8Array( pdfBytes ); 
        const blob   = new Blob( [ bytes ], { type: "application/pdf" } );
        const docUrl = URL.createObjectURL( blob );
    //   const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
      // document.getElementById('pdf').src = pdfDataUri;
      setPdf(docUrl);
    }
    createPdf()
  }, [])
  

  return (
    <div>
      {pdf ? <iframe src={uri + "#toolbar=0&view=fitH"} className='d-block mx-auto' style={{ width: "70%", height: "1000px",}} /> : <div style={{ width: "100%", height: "1000px",}} className='flex justify-center items-center'><Spinner color="amber" className="h-12 w-12" /></div>}
    </div>
  );
}

export default Files