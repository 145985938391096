import {
    Typography,
    Card,
  } from "@material-tailwind/react";
   
  export default function CurrentIssue() {
   
    return (
      <>
          <Card className="mb-12 overflow-hidden pointer-events-none select-none">
            <img
              alt="nature"
              className="h-[32rem] w-full object-cover object-center"
              src="https://images.unsplash.com/photo-1485470733090-0aae1788d5af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2717&q=80"
            />
          </Card>
          <Typography variant="h2" color="blue-gray" className="mb-2">
            Joriy nashrlar
          </Typography>
      </>
    );
  }