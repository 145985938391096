import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { useState } from "react";
import bg from "../assets/images/abstract-bg.jpg";
import Footer from "./Footer";
import Indexing from "./pages/Indexing";
import { ScrollRestoration } from "react-router-dom";

const Layout = () => {
  
  return (
    <>
      <div className=" h-full !bg-fixed !bg-cover !bg-center !bg-no-repeat" style={{ background: `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), url(${bg})`, backgroundColor: "rgba(0,0,0,0.25)" }}>
      <ScrollRestoration />
        <Navbar />
        <div className="mx-auto max-w-screen-2xl py-12 px-3">
          <Outlet />
        <div className="relative flex py-5 items-center my-5">
          <div className="flex-grow border-t border-gray-400"></div>
          {/* <span className="flex-shrink mx-4 text-gray-700 text-2xl">Jurnallar</span> */}
        <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <Indexing />
        </div>
      </div>
      <Footer />
    </>
  )
};

export default Layout;