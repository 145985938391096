import React from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Avatar,
    IconButton,
    Button,
    Spinner,
} from "@material-tailwind/react";
import { BsArrowRight } from "react-icons/bs";
import muqova from "../../assets/images/881.jpg";
import { Helmet } from 'react-helmet-async';

import { db } from "../../firebase-config.js";
import { doc, getDocs, collection, query, orderBy } from "firebase/firestore";

const articlesRef = collection(db, "articles");
const articlesQuery = query(articlesRef, orderBy("order"));

function Journal() {
    const { journal } = useParams();
    const [articles, setArticles] = React.useState([]);
    React.useEffect(() => {
        const getArticles = async () => {
            // const querySnapshot = await getDocs(articlesQuery);
            // console.log(querySnapshot);
            // const articles = [];
            // querySnapshot.forEach((doc) => {
            //     articles.push({
            //         id: doc.id,
            //       ...doc.data(),
            //     });
            // });
            const articles = await fetch(`https://next-chi-two.vercel.app/api/articles`).then((res) => res.json());
            setArticles(articles);
        };
        getArticles();
    }, []);
    return (
        <>
        <Helmet>
            <title>"Ilm-Fan" Journal of Science</title>
            <meta name="description" content='"Ilm-Fan" Journal of Science, "Ilm-Fan" Ilmiy ommabop jurnali' />
        </Helmet>
            <Card
      shadow={false}
      className="relative grid h-[40rem] w-full max-w-[28rem] mx-auto items-end justify-center overflow-hidden text-center"
    >
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
        style={{ backgroundImage: `url(${muqova})` }}
      >
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
      </CardHeader>
      <CardBody className="relative py-14 px-6 md:px-12">
        <Typography
          variant="h2"
          color="white"
          className="mb-6 font-medium leading-[1.5]"
        >
          "Ilm-Fan" Journal of Science
        </Typography>
        {/* <Avatar
          size="xl"
          variant="circular"
          alt="candice wu"
          className="border-2 border-white"
          src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
        /> */}
      </CardBody>
    </Card>
    <div className='ml-5 mt-5'>
        {
            articles.length > 0 ? articles.map((article) => (
                <div key={article.id} className='p-5' style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant="h5">
                        {article.title}
                    </Typography>
                    <Typography variant="h6" className="text-bold">
                        {article.author.map((author) => author + ",")}
                    </Typography>
                    <div className='flex flex-row'>
                        <Link target='_blank' to={article.doi}>
                        <Button variant="gradient" className="flex items-center gap-3">
                            View On ZENODO <BsArrowRight className="h-5 w-5" />
                        </Button>
                        </Link>
                        <Link className='ml-5' to={`/article/${article.id}`}>
                        <Button variant="gradient" className="flex items-center gap-3">
                            View Article <BsArrowRight className="h-5 w-5" />
                        </Button>
                        </Link>
                    </div>
                </div>
            )) : (<div className="m-auto content-center"><Spinner className="h-16 w-16 text-blue-500/10" /></div>)
        }
    </div>
        </>
    );
}

export default Journal;