import React from 'react';
// import { Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout';
import Home from "../components/pages/Home";
import Blogs from "../components/pages/Blogs";
import Contact from "../components/pages/Contact";
import NoPage from "../components/pages/NoPage";
import Users from "../components/pages/Users";
import Journals from "../components/pages/Journals";
import CurrentIssue from '../components/pages/CurrentIssue';
import Issues from '../components/pages/Issues';
import Journal from '../components/pages/Journal';
import Conferences from '../components/pages/Conferences';
import Archive from '../components/pages/Archive';
import Article from '../components/pages/Article';
import Files from '../components/pages/Files';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

function AppRoutes() {
  const router = createBrowserRouter([
    {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        index: true,
        element: <Home />,
      },
      {
        path: "journals",
        element: <Journals />,
      },
      {
        path: "journals/:journal",
        element: <Journal />,
      },
      {
        path: "current",
        element: <CurrentIssue />,
      },
      {
        path: "issues",
        element: <Issues />,
      },
      {
        path: "conferences",
        element: <Conferences />,
      },
      {
        path: "article/:id",
        element: <Article />,
      },
      {
        path: "files/:id",
        element: <Files />,
      },
      {
        path: "*",
        element: <NoPage />,
      },
    ],
  },
  {
    path: "/api",
    // element: (<div>whoa</div>),
    children: [
      {
        path: "users",
        index: true,
        element: <div>users</div>,
      },
      {
        path: "*",
        element: <div>404</div>,
      },
    ],
  }
]);
  return (
    <RouterProvider router={router} />
  );
}

export default AppRoutes;

      //   <Routes>
      //     <Route path="/" element={<Layout />}>
      //         <Route index element={<Home />} />
      //         <Route path="blogs" element={<Blogs />} />
      //         {/* <Route path="contact" element={<Contact />} /> */}
      //         {/* <Route path="user" element={<Users />} /> */}
      //         <Route path="journals" element={<Journals />} />
      //         <Route path="journals/:journal" element={<Journal />} />
      //         <Route path="current" element={<CurrentIssue />} />
      //         <Route path="issues" element={<Issues />} />
      //         <Route path="archive" element={<Archive />} />
      //         <Route path="conferences" element={<Conferences />} />
      //         <Route path="article/:id" element={<Article />} />
      //         <Route path="files/:id" element={<Files />} />
      
      //         <Route path="*" element={<NoPage />} />
      //     </Route>
      // </Routes>