import {
  Typography,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import muqova from "../../assets/images/881.jpg";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
   
  export default function Journals() {
   
    return (
      <>
        <Card className="flex-row w-full max-w-[48rem]">
      <CardHeader shadow={false} floated={false} className="w-2/5 shrink-0 m-0 rounded-r-none">
        <img 
          src={muqova}
          alt="muqova"
          className="w-full h-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h6" color="blue" className="uppercase mb-4">ILM-FAN</Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          "ILM-FAN" Journal of science
        </Typography>
        <Typography color="gray" className="font-normal mb-8">
          Google Scholar, ZENODO, DOI, OpenAire, Open Access ilmiy-ommabop maqolalarni indexlovchi bazalarda indexlanuvchi AOKA(Axborot va Ommaviy Kommunikatsiya Agentligi) tomonidan litsenziyalangan jurnal
        </Typography>
        <Link to="/journals/ifjs" className="inline-block">
          <Button variant="text" className="flex items-center gap-2">
            Jurnalga o'tish 
            <ArrowLongRightIcon strokeWidth={2} className="w-4 h-4" />
          </Button>
        </Link>
      </CardBody>
    </Card>
      </>
    );
  }